import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { HttpClientModule } from '@angular/common/http';
import { registerLocaleData } from '@angular/common';

import {
  DateAdapter,
  MAT_DATE_FORMATS,
  MAT_DATE_LOCALE,
} from '@angular/material/core';
import { MomentDateAdapter } from '@angular/material-moment-adapter';

import localeHu from '@angular/common/locales/hu';
registerLocaleData(localeHu, 'hu');

import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';

import { LoginModule } from './login/login.module';
import { DashboardModule } from './dashboard/dashboard.module';
import { MatDialogModule } from '@angular/material/dialog';
import { GeneralLoadingDialogModule } from './dialogs/general-loading-dialog/general-loading-dialog.module';
import { GeneralSuccessDialogModule } from './dialogs/general-success-dialog/general-success-dialog.module';
import { WorkflowDialogModule } from './shared/workflow-dialogs/workflow-dialog.module';
import { AuthService } from 'src/app/shared/services/auth/auth.service';
import {
  OauthRedirectModule as PwcOauthRedirectModule,
} from 'src/app/shared/pwc/oauth-redirect/oauth-redirect.module';
import { UserNotFoundModule } from './shared/user-not-found/user-not-found.module';

class CustomDateAdapter extends MomentDateAdapter {
  getDayOfWeekNames(style: 'long' | 'short' | 'narrow'): string[] {
    const long = ['Vasárnap', 'Hétfő', 'Kedd', 'Szerda', 'Csütörtök', 'Péntek', 'Szombat'];
    // const narrow = ['Vasárnap', 'Hétfő', 'Kedd', 'Szerda', 'Csütörtök', 'Péntek', 'Szombat'];
    const short = ['Vas', 'Hét', 'Kedd', 'Szer', 'Csüt', 'Pén', 'Szom'];
    switch (style) {
      case 'long': return long;
      case 'narrow': return short;
      case 'short': return short;
      default: return long;
    }
  }

  getMonthNames(style: 'long' | 'short' | 'narrow'): string[] {
    const long = ['Január', 'Február', 'Március', 'Április', 'Május', 'Június', 'Július', 'Augusztus', 'Szeptember', 'Október', 'November', 'December'];
    // const narrow = ['Január', 'Február', 'Március', 'Április', 'Május', 'Június', 'Július', 'Augusztus', 'Szeptember', 'Október', 'November', 'December'];
    const short = ['Jan', 'Feb', 'Már', 'Ápr', 'Máj', 'Jún', 'Júl', 'Aug', 'Szep', 'Okt', 'Nov', 'Dec'];
    switch (style) {
      case 'long': return long;
      case 'narrow': return short;
      case 'short': return short;
      default: return long;
    }
  }
}

export const DateFormats = {
  parse: {
    dateInput: ['YYYY.MM.DD.']
  },
  display: {
    dateInput: 'YYYY.MM.DD.',
    monthYearLabel: 'YYYY.MM.DD.',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'MMMM YYYY',
  },
};

@NgModule({
  declarations: [
    AppComponent,
  ],
  imports: [
    AppRoutingModule,
    BrowserAnimationsModule,
    BrowserModule,
    HttpClientModule,
    LoginModule,
    DashboardModule,
    MatDialogModule,
    GeneralLoadingDialogModule,
    GeneralSuccessDialogModule,
    WorkflowDialogModule,
    PwcOauthRedirectModule,
    UserNotFoundModule,
  ],
  providers: [
    { provide: MAT_DATE_FORMATS, useValue: DateFormats },
    { provide: DateAdapter, useClass: CustomDateAdapter, deps: [MAT_DATE_LOCALE] },
  ],
  bootstrap: [AppComponent]
})


export class AppModule {
  constructor(
    protected authService: AuthService,
  ) {
  }
}

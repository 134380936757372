<div class="login-container">
  <div class="login-box">
    <div class="small-title">Üdvözöljük újra oldalunkon</div>
    <div class="big-title top-margin-20">Real-time Debt Management Tool</div>
    <div class="login-button-box top-margin-30">
      <ap-button
        style="width: 100%"
        [btnType]="'primary'"
        [label]="'Bejelentkezés'"
        (onClick)="pwcSso()"
      >
      </ap-button>
    </div>
  </div>
</div>

<div class="background">
  <img
    src="assets/shared/global-images/partner-logos/pwc-login-background.svg"
  />
</div>

<div class="pwc-icon">
  <payee-icon
    [name]="'pwc_logo_bw_white'"
    [height]="'120px'"
    [width]="'120px'"
  ></payee-icon>
</div>

import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { PageEvent } from '@angular/material/paginator';
import { User } from 'src/types';
import { Subject } from 'rxjs';
import { debounceTime, filter, takeUntil } from 'rxjs/operators';
import { AuthService } from 'src/app/shared/services/auth/auth.service';
import { CasesService, CasesTableData } from 'src/app/services/cases/cases.service';
import { ClientsService, GetClientsResponse } from 'src/app/services/clients/clients.service';
import { DebtorsService } from 'src/app/services/debtors/debtors.service';
import { UsersService } from 'src/app/services/users/users.service';

@Component({
  selector: 'app-cases',
  templateUrl: './cases.component.html',
  styleUrls: ['./cases.component.scss']
})
export class CasesComponent implements OnInit, OnDestroy {
  selectorForm: FormGroup;

  private readonly subKiller = new Subject<void>();

  userName: string;

  clients: GetClientsResponse['clients'] = [];
  selectedClient?: GetClientsResponse['clients'][number];

  debtorNames: string[] = [];
  selectedDebtor: string;

  tableData: CasesTableData[] = [];

  pageSize: number = 10;
  pageIndex: number = 0;
  length: number = 0;

  loading = false;
  casesLoading = false;

  get caseFilter() { return this.selectorForm.get('caseFilter'); }
  get clientFilter() { return this.selectorForm.get('clientFilter'); }
  get debtorFilter() { return this.selectorForm.get('debtorFilter'); }

  constructor(
    private authService: AuthService,
    private casesService: CasesService,
    private clientsService: ClientsService,
    private debtorsService: DebtorsService,
    private usersService: UsersService,
    private fb: FormBuilder,
  ) {
    this.selectorForm = this.fb.group({
      caseFilter: '',
      clientFilter: '',
      debtorFilter: '',
    });
  }

  async ngOnInit() {
    this.clientFilter.valueChanges
      .pipe(takeUntil(this.subKiller), filter((f: string) => f?.length > 2 && !this.loading), debounceTime(1000))
      .subscribe({
        next: async (filter: string) => {
          try {
            this.loading = true;
            const res = await this.clientsService.searchClients({
              name: filter,
            });
            this.clients = res.clients;
          } catch (error) {
            console.error(error);
          } finally {
            this.loading = false;
          }
        },
      });
    this.debtorFilter.valueChanges
      .pipe(takeUntil(this.subKiller), filter((f: string) => f?.length > 2 && !this.loading), debounceTime(1000))
      .subscribe({
        next: async (filter: string) => {
          try {
            this.loading = true;
            const res = await this.debtorsService.searchDebtors({
              name: filter,
              client_id: this.selectedClient?.id,
            });
            this.debtorNames = res.debtors.map(debtor => debtor.name);
          } catch (error) {
            console.error(error);
          } finally {
            this.loading = false;
          }
        },
      });

    this.caseFilter.valueChanges
      .pipe(takeUntil(this.subKiller), debounceTime(1000))
      .subscribe({
        next: async () => {
          this.pageIndex = 0;
          await this.getCases();
        },
      });

    const [user] = await Promise.all([
      this.usersService.getUser(),
      this.getCases(),
    ]);
    this.userName = user.name;
  }

  ngOnDestroy(): void {
    this.subKiller.next();
    this.subKiller.complete();
  }

  readonly displayWith = <T extends { name: string; }>(e: T) => e.name;
  readonly filterPredicate = <T extends { name: string; }>(e: T, filter: string) => e.name.toLowerCase().includes(filter.toLowerCase());

  private async getCases() {
    let page = this.pageIndex + 1;

    const payee_case_id = this.caseFilter.value || undefined;

    const result = await this.casesService.getCases({
      per_page: this.pageSize,
      page,
      client_id: this.selectedClient?.id,
      debtor_name: this.selectedDebtor,
      payee_case_id,
    });

    this.tableData = result.data;
    this.length = result.pagination.total;
    this.pageIndex = result.pagination.currentPage - 1;
    this.pageSize = result.pagination.perPage;
  }

  readonly clientSelected = async (client?: GetClientsResponse['clients'][number]) => {
    if (client?.id === this.selectedClient?.id) {
      return;
    }
    this.selectedClient = client;
    this.pageIndex = 0;

    await this.getCases();
  };

  readonly debtorSelected = async (debtor?: string) => {
    if (debtor === this.selectedDebtor) {
      return;
    }
    this.selectedDebtor = debtor;
    this.pageIndex = 0;

    await this.getCases();
  };

  async pageChange(event: PageEvent) {
    this.pageIndex = event.pageIndex;
    this.pageSize = event.pageSize;

    await this.getCases();
  }

  async logout() {
    await this.authService.logout();
  }

  filterCase(id: string): void {
    this.caseFilter.patchValue(id);
  }
}

import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { DashboardComponent } from './dashboard.component';
import { RouterModule } from '@angular/router';
import { SidebarComponent } from './sidebar/sidebar.component';

import { PayeeButtonsModule } from 'src/app/shared/payee-buttons/payee-buttons.module';
import { CasesModule } from './cases/cases.module';
import { MatIconModule } from '@angular/material/icon';

@NgModule({
  declarations: [
    DashboardComponent,
    SidebarComponent
  ],
  imports: [
    CommonModule,
    RouterModule,
    PayeeButtonsModule,
    CasesModule,
    MatIconModule,
  ],
})
export class DashboardModule { }

import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { PayeePopupModule } from 'src/app/shared/payee-popup/payee-popup.module';

import { GeneralLoadingDialogComponent } from './general-loading-dialog.component';

@NgModule({
  declarations: [
    GeneralLoadingDialogComponent,
  ],
  imports: [
    CommonModule,
    PayeePopupModule,
  ],
  schemas: [
    CUSTOM_ELEMENTS_SCHEMA,
  ],
})
export class GeneralLoadingDialogModule { }

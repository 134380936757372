import { Injectable } from '@angular/core';
import axios from 'axios';
import { environment } from 'src/environments/environment';

export type GetUserResponse = {
  name: string;
}

@Injectable({
  providedIn: 'root'
})
export class UsersService {
  async getUser(): Promise<GetUserResponse> {
    const url = environment.baseUrl + `/api/user`;
    const result = await axios.get<GetUserResponse>(url);
    return result.data;
  }
}

import { Injectable } from '@angular/core';
import { User } from 'src/types';

@Injectable({
  providedIn: 'root'
})
export class AttorneyService {
  private attorneyUsers?: Partial<User>[];

  async getAttorneyUsers(forceRefresh = false): Promise<Partial<User>[]> {
    throw new Error('Unimplemented');

    if (this.attorneyUsers && !forceRefresh) {
      return this.attorneyUsers.concat();
    }

    return this.attorneyUsers.concat();
  }
}

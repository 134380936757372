import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { PayeeButtonsModule } from 'src/app/shared/payee-buttons/payee-buttons.module';

import { CasesComponent } from './cases.component';
import { PayeeFormFieldsModule } from 'src/app/shared/payee-form-fields/payee-form-fields.module';
import { PayeeInputModule } from 'src/app/shared/payee-input/payee-input.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { TableModule } from './table/table.module';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { PayeeIconModule } from 'src/app/shared/payee-icon/payee-icon.module';
import { PayeeRadioModule } from 'src/app/shared/payee-radio/payee-radio.module';

@NgModule({
  declarations: [
    CasesComponent,
  ],
  imports: [
    CommonModule,
    PayeeButtonsModule,
    PayeeFormFieldsModule,
    PayeeInputModule,
    PayeeIconModule,
    PayeeRadioModule,
    ReactiveFormsModule,
    FormsModule,
    TableModule,
    MatProgressSpinnerModule,
    MatAutocompleteModule,
  ],
})
export class CasesModule { }

import { Injectable } from '@angular/core';
import { Client } from 'src/types';
import { HelpersService } from '../helpers/helpers.service';
import { environment } from 'src/environments/environment';
import axios from 'axios';

export interface GetClient {
  clientId: string;
}

export interface GetClientsWithIds {
  clientIds: string[];
}

export type GetClientsParams = {
  name: string;
};

export type GetClientsResponse = {
  clients: {
    name: string;
    id: string;
  }[];
};

@Injectable({
  providedIn: 'root'
})
export class ClientsService {
  private readonly clients = new Map<string, Client>();

  constructor(
    private helpersService: HelpersService,
  ) {
  }

  async getClient({ clientId }: GetClient) {
    throw new Error('Unimplemented');
  }

  async getClientsWithIds({ clientIds }: GetClientsWithIds): Promise<Client[]> {
    throw new Error('Unimplemented');
  }

  async searchClients(params: GetClientsParams): Promise<GetClientsResponse> {
    const url = environment.baseUrl + '/api/client';
    const result = await axios.get<GetClientsResponse>(
      url,
      {
        params,
      },
    );

    return result.data;
  }
}

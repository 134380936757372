import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { User } from 'src/types';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { AttorneyService } from 'src/app/services/attorney/attorney.service';
import { CasesService } from 'src/app/services/cases/cases.service';
import { LoadingDialogService } from 'src/app/services/loading-dialog/loading-dialog.service';
import { environment } from '../../../environments/environment';

export interface UploadFilesData {
  caseId: string;
}

@Component({
  selector: 'app-upload-files-dialog',
  templateUrl: './upload-files-dialog.component.html',
  styleUrls: ['./upload-files-dialog.component.scss']
})
export class UploadFilesDialogComponent implements OnInit, OnDestroy {
  files: File[] = [];
  selectedAttorneys: Partial<User>[] = [];
  attorneys: Partial<User>[];
  attorneyControl = new FormControl();

  private readonly subKiller = new Subject();

  get valid() {
    return this.files.length > 0 && this.selectedAttorneys.length > 0;
  }

  constructor(
    @Inject(MAT_DIALOG_DATA) private data: UploadFilesData,
    private dialogRef: MatDialogRef<UploadFilesDialogComponent>,
    private attorneyService: AttorneyService,
    private casesService: CasesService,
    private loadingDialogService: LoadingDialogService,
  ) { }

  async ngOnInit() {
    this.attorneys = await this.attorneyService.getAttorneyUsers();

    this.attorneyControl.valueChanges
      .pipe(takeUntil(this.subKiller))
      .subscribe({
        next: attorney => {
          if (typeof attorney === 'object') {
            this.selectedAttorneys.push(attorney);
            this.attorneys.splice(this.attorneys.indexOf(attorney), 1);
            this.attorneyControl.patchValue('', { emitEvent: false });
          }
        },
      });
  }

  ngOnDestroy(): void {
    this.subKiller.next();
    this.subKiller.complete();
  }

  displayAttorney(attorney: Partial<User>) {
    return attorney.name;
  }

  filter = (data: Partial<User>, filter: string): boolean => {
    if (!filter) {
      return true;
    }
    if (typeof filter !== 'string') {
      return filter === data;
    }
    const displayed = this.displayAttorney(data);
    return displayed.toLowerCase().includes(filter.toLowerCase());
  };

  unselectAttorney(attorney: Partial<User>) {
    this.selectedAttorneys.splice(this.selectedAttorneys.indexOf(attorney), 1);
    this.attorneys.push(attorney);
  }

  removeFile(file: File) {
    this.files.splice(this.files.indexOf(file), 1);
  }

  onDrop(files: FileList) {
    for (let i = 0; i < files.length; ++i) {
      this.files.push(files.item(i));
    }
  }

  async onSubmit() {
    if (!this.valid || this.loadingDialogService.isLoading()) {
      return;
    }
    try {
      this.loadingDialogService.startLoading();

      const attorneys = this.selectedAttorneys.map(attorney => attorney.id);
      await this.casesService.uploadFilesToCase(this.data.caseId, attorneys, this.files);

      this.loadingDialogService.stopLoading(true);
      this.dialogRef.close();
    } catch (error) {
      this.loadingDialogService.stopLoading(false);
      console.error(error);
    }
  }

  readonly environment = environment;
}

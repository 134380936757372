import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatDialogModule } from '@angular/material/dialog';
import { PayeeButtonsModule } from 'src/app/shared/payee-buttons/payee-buttons.module';
import { PayeeDropzoneModule } from 'src/app/shared/payee-dropzone/payee-dropzone.module';
import { PayeePopupModule } from 'src/app/shared/payee-popup/payee-popup.module';
import { PayeeFormFieldsModule } from 'src/app/shared/payee-form-fields/payee-form-fields.module';
import { UploadFilesDialogComponent } from './upload-files-dialog.component';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { PayeeIconModule } from 'src/app/shared/payee-icon/payee-icon.module';



@NgModule({
  declarations: [
    UploadFilesDialogComponent,
  ],
  imports: [
    CommonModule,
    PayeePopupModule,
    PayeeButtonsModule,
    PayeeDropzoneModule,
    MatDialogModule,
    PayeeFormFieldsModule,
    MatProgressSpinnerModule,
    ReactiveFormsModule,
    FormsModule,
    PayeeIconModule,
  ],
})
export class UploadFilesDialogModule { }

import { Component, HostBinding } from '@angular/core';
import { AuthService } from 'src/app/shared/services/auth/auth.service';
import packageJSON from '../../../../package.json';
import { environment } from '../../../environments/environment';

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.scss']
})
export class SidebarComponent {
  @HostBinding('class.closed') closed = false;

  constructor(
    private authService: AuthService,
  ) { }

  get version() { return packageJSON.version; }

  async logout() {
    await this.authService.logout();
  }

  readonly environment = environment;
}

import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { PayeePopupModule } from 'src/app/shared/payee-popup/payee-popup.module';

import { GeneralSuccessDialogComponent } from './general-success-dialog.component';
import { PayeeButtonsModule } from 'src/app/shared/payee-buttons/payee-buttons.module';
import { MatDialogModule } from '@angular/material/dialog';

@NgModule({
  declarations: [
    GeneralSuccessDialogComponent,
  ],
  imports: [
    CommonModule,
    PayeePopupModule,
    PayeeButtonsModule,
    MatDialogModule,
  ],
  schemas: [
    CUSTOM_ELEMENTS_SCHEMA,
  ],
})
export class GeneralSuccessDialogModule { }
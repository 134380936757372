import { CommonModule } from '@angular/common';
import { MatMenuModule } from '@angular/material/menu';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatTableModule } from '@angular/material/table';
import { MatTabsModule } from '@angular/material/tabs';
import { NgModule } from '@angular/core';
import { PayeeButtonsModule } from 'src/app/shared/payee-buttons/payee-buttons.module';
import { PayeeHorizontalLineModule } from 'src/app/shared/payee-horizontal-line/payee-horizontal-line.module';
import { PayeeIconModule } from 'src/app/shared/payee-icon/payee-icon.module';
import { TableComponent } from './table.component';
import { PayeeDropzoneModule } from 'src/app/shared/payee-dropzone/payee-dropzone.module';
import { UploadFilesDialogModule } from 'src/app/dialogs/upload-files-dialog/upload-files-dialog.module';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { CaseEventsModule } from 'src/app/shared/case-events/cases-events.module';
import { CaseDetailsModule } from 'src/app/shared/case-details/cases-details.module';



@NgModule({
  declarations: [
    TableComponent,
  ],
  imports: [
    CommonModule,
    MatTableModule,
    PayeeIconModule,
    MatPaginatorModule,
    MatProgressSpinnerModule,
    MatMenuModule,
    PayeeButtonsModule,
    MatTabsModule,
    PayeeHorizontalLineModule,
    UploadFilesDialogModule,
    PayeeDropzoneModule,
    MatTooltipModule,
    MatSnackBarModule,
    CaseEventsModule,
    CaseDetailsModule,
  ],
  exports: [
    TableComponent,
  ]
})
export class TableModule { }

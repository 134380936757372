import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { LoginComponent } from './login.component';

import { PayeeHeaderModule } from 'src/app/shared/payee-header/payee-header.module';
import { PayeeFormFieldsModule } from 'src/app/shared/payee-form-fields/payee-form-fields.module';
import { PayeeInputModule } from 'src/app/shared/payee-input/payee-input.module';
import { PayeeButtonsModule } from 'src/app/shared/payee-buttons/payee-buttons.module';

import { MatFormFieldModule } from '@angular/material/form-field';
import { RouterModule } from '@angular/router';
import { PayeeIconModule } from '../shared/payee-icon/payee-icon.module';
import { ButtonModule } from '@appkit4/angular-components/button';

@NgModule({
  declarations: [
    LoginComponent
  ],
  imports: [
    CommonModule,
    RouterModule,
    PayeeHeaderModule,
    PayeeFormFieldsModule,
    PayeeButtonsModule,
    ReactiveFormsModule,
    FormsModule,
    MatFormFieldModule,
    PayeeInputModule,
    PayeeIconModule,
    ButtonModule,
  ],
})
export class LoginModule { }

import { NgModule } from "@angular/core";
import { RouterModule, Routes } from "@angular/router";
import { CasesComponent } from "./dashboard/cases/cases.component";
import { DashboardComponent } from "./dashboard/dashboard.component";
import { LoginComponent } from "./login/login.component";
import { AuthGuardService } from "./services/auth-guard/auth-guard.service";
import {
  OauthRedirectComponent as PwcOauthRedirectComponent,
} from 'src/app/shared/pwc/oauth-redirect/oauth-redirect.component';
import { UserNotFoundComponent } from "./shared/user-not-found/user-not-found.component";

const routes: Routes = [
  { path: 'login', component: LoginComponent },
  { path: 'user-not-found', component: UserNotFoundComponent },
  {
    path: 'attorney',
    component: DashboardComponent,
    canActivate: [AuthGuardService],
    children: [
      { path: 'cases', component: CasesComponent },
      { path: '**', redirectTo: 'cases' },
    ],
  },
  {
    path: 'oauth',
    children: [
      { path: 'pwc', component: PwcOauthRedirectComponent },
    ],
  },
  { path: '**', redirectTo: 'login' },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})

export class AppRoutingModule { }
